<template>
  <div>
    
    <div class="border border-solid border-blue-200">
      <datatable
        :data="repayments.data"
        :columns="repayments.columns"
        :query="searchQuery"
        :loading="repayments.loading"
        :index="true"
        :on-click="click"
        :selectable="true"
        :exportable="true"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/repayments/unsettled-loans`"
        :search-field="searchField"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} Repayment{{
          selection.length !== 1 ? "s" : ""
        }}
        Selected!
      </div>

      <template v-if="getFormError(settlementForm)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(settlementForm) }}</span>
        </div>
      </template>

      <div class="mb-10 max-h-200px overflow-y-auto">
        <template v-for="(failure, n) in repayments.failures">
          <div
            :class="{
              'border-b border-blue-200 pb-1 mb-2':
                n < repayments.failures.length - 1
            }"
            :key="n"
          >
            <div class="flex items-center text-xs">
              <span class="mr-3">Repayment ID: {{ failure.repayment.id }}</span>
              <span>Reason: {{ failure.reason }}</span>
            </div>
          </div>
        </template>
      </div>

      <form-group
        type="select"
        :options="settlementOptions"
        left-icon="business-outline"
        name="settlement"
        :loading="settlementForm.loading"
        v-model="settlementForm.data.settlementStatus.value"
        :form="settlementForm"
      >
        Settlement Status
      </form-group>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="settle"
          :disabled="
            settlementForm.loading || !selection.length || !settlementForm.data.settlementStatus.value
          "
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="settlementForm.loading">Settling</span>
          <span v-else>Settle Repayment</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="settlementForm.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        {{ successMessage }}
      </div>
    </modal>

  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    searchQuery: {
      type: String,
      default: ""
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require("@/assets/checkmark-base.svg"),
      settlementOptions: ["settled", "unsettled"],
      settlementForm: this.$options.basicForm([
        { name: "settlementStatus", rules: "required" }
      ]),
      repayments: this.$options.resource([], {
        selected: null,
        columns: [
          {
            name: "name",
            th: "User Name",
            render: repayment => `${repayment.user?.name} ${repayment.user?.last_name || ""}`
          },
          {
            name: "phone",
            th: "Phone No",
            render: repayment => repayment.user.phone_no
          },
          {
            name: "loan_amount",
            th: "Amount Disbursed",
            render: repayment =>
              `₦ ${this.$options.filters.currency(repayment.loan.loan_amount)}`
          },
          {
          name: "due_date",
          th: "Due Date",
          render: (repayment, due_date) =>
            moment(due_date).format("MMM Do YYYY")
        },
        {
          name: "tenure",
          th: "Loan Tenure",
          render: repayment => repayment?.loan.tenure || "N/A"
        
        },
        {
          name: "interest_rate",
          th: "Interest Rate",
          render: repayment => `${repayment?.loan?.interest_rate}%` || "N/A"
        },
        {
          name: "amount",
          th: "Amount",
          render: (repayment, amount) =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              amount
            )}</span>`
        },
        {
          name: "default_charge",
          th: "Default Charge",
          render: repayment =>
            `<span className="text-blue-300">₦ ${this.$options.filters.currency(
              repayment.default_charge?.amount || 0
            )}</span>`
        },
        {
          name: "status",
          th: "Status",
          render: repayment => {
            const today = moment().format("YYYY-MM-DD");
            switch (repayment.status) {
              case "paid":
                return `<div class="badge badge-green-soft-outline">
  										Paid
  									</div>`;
              case "transferred":
                return `<div class="badge badge-green-soft-outline">
  										Transferred
  									</div>`;
              case "not_due":
                if (today > repayment.due_date) {
                  return `<div class="badge badge-orange-soft-outline">
  										Due
  									</div>`;
                }
                return `<div class="badge badge-blue-soft-outline">
  										Not Due
  									</div>`;
              case "due":
                if (repayment.default_charge) {
                  return `<div class="badge badge-red-soft-outline">
                      Defaulting
                    </div>`;
                }
                return `<div class="badge badge-orange-soft-outline">
  										Due
  									</div>`;
              case "processing":
                return `<div class="badge badge-blue-soft-outline">
  										Processing
                    </div>`;
              case "failed":
                return `<div class="badge badge-red-soft-outline">
  										Failed
  									</div>`;
              case "pending":
                return `<div class="badge badge-orange-soft-outline">
  										Pending
                    </div>`;
              default:
                if (repayment.defaulted) {
                  return `<div class="badge badge-red-soft-outline">
  											Default
  										</div>`;
                }
                break;
            }
          }
        },
        {
          name: "payment_method",
          th: "Payment Method",
          render: repayment => {
            if (!repayment?.payment_method) {
              return "N/A";
            }
            return repayment?.payment_method;
          }
        },
        {
          name: "settlement_status",
          th: "Settlement Status",
          render: repayment => {
            switch (repayment?.settlement_status) {
              case "settled":
                return `<div class="badge badge-green-soft-outline">
  										Settled
  									</div>`;
              case "unsettled":
                return `<div class="badge badge-red-soft-outline">
                    Unsettled
                  </div>`;
              default:
                return `<div class="badge badge-blue-soft-outline">
                      Pending
                  </div>`;
            }
          }
        },
        {
          name: "id",
          th: "Repayment ID",
          render: repayment => repayment?.id
        },
        {
          name: "loan",
          th: "Disburse Date",
          render: (repayment, loan) => {
            if (!loan?.disbursed_at) {
              return "N/A";
            }
            return moment(loan.disbursed_at).format("MMM Do YYYY");
          }
        },
        {
          className: "w-1/10",
          name: "comment",
          th: "Comments",
          render: repayment => {
            return `
                  <div class="flex items-center">
                    <button
                      class="btn btn-blue btn-sm mr-5"
                      data-click="click(${repayment?.id})"
                    >
                      View
                    </button>
                  </div>
                `;
          }
        }
        ],
        successes: [],
        failures: []
      }),
      loading: false,
      selected: null,
      successMessage: null,
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    selectedUser() {
      return this.repayments.selected?.user;
    },
  },
  beforeMount() {
    
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(repayment) {
      this.repayments.selected = repayment;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },

    async settle() {
      if (!this.validateForm(this.settlementForm)) {
        return false;
      }

      this.repayments.successes = [];
      this.repayments.failures = [];

      this.settlementForm.loading = true;
      this.settlementForm.error = null;
      await this.sendRequest("admin.loans.personal.settle_many_repayments", {
        data: {
          repayments: this.selection.map(item => item.row.id),
          settlement_status: this.settlementForm.data.settlementStatus.value
        },
        success: (response) => {
          this.reloadTable();
          const successes = response.data?.successes;
          const failures = response.data?.failures;

          this.repayments.successes = successes;
          this.repayments.failures = failures;

          this.$success({
            title: 'Repayments settled!',
            body: 'The user(s) will be notified that their repayment(s) was successful',
            button: 'Okay',
          });

          if (this.repayments.successes.length) {
            this.clear();
          }
        },
        error: (error) => {
          this.settlementForm.error = error;

          if (error?.response?.data?.failures) {
            this.repayments.failures = error?.response?.data?.failures;
          }
        }
      });
      this.settlementForm.loading = false;
    },
  }
};
</script>
